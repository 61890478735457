<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Trevor Nicholls team building with a group of people before a congress'"
  >
    <h1>Booth Training</h1>
      <div class="scrollable-content">
    <p>
      We offer our Booth Training programme which is designed to support
      businesses in the running of their booths at exhibitions. This can be as
      part of a pre-congress welcome meeting or as a stand alone session.
    </p>
    <p>
      The activity can also be part of a separate training session to build
      unity, encourage interaction, build confidence and break down barriers in
      the work place.
    </p>
    <p>
      In this relaxed and enjoyable programme that is facilitated by our team we
      will help by training employees to encourage, empower, and engage delegates
      so that we can create a great booth ambience and positive experience for
      representatives. This is a vital tool in aiding their communication
      between delegates and maximising their potential during the exhibition
      hours.
    </p>
    <p>
      This can be tailored for requirements and duration incorporating group
      tasks, enactments, team build projects, tips and advice, what to do and
      what to avoid, reaffirming the objectives, well being and gaining
      communication attributes.
    </p>
    <p>
      We start with an introduction and purpose of the programme of exercises
      these are designed to develop good communication, well-being and help
      promote a positive and engaging presence on the booth. We would go through
      some pre-booth exercises to relax, focus and at the same time engage
      enthusiasm for the coming days ahead.
    </p>
    <h3>Observations</h3>
    <p>
      <span
        >Here we have some enjoyable exercises in stimulating the senses,
        reading body language, first impressions together with group memory
        activities.</span
      >
    </p>
    <h3>Building The Team</h3>
    <p>
      <span
        >This includes a series of challenges such as team profiling, identity
        rounds, interactive quiz challenges, creative exercises, Key objectives,
        Key messaging and enactments as a group followed by analysis.</span
      >
    </p>
    <h3>What makes a successful booth?</h3>
    <p>
      We then ask for input from the reps about what their importance and
      priorities are that make a successful booth experience and support this
      with the overall tailored objective so everyone is well briefed. We will
      discuss the booth etiquette and what to observe and what to avoid.
    </p>
    <h3>Let the show commence!</h3>
    <p>
      This is the final build towards the start, getting everyone excited and
      making sure everyone understands the objectives as a valued member of the
      team.
    </p>
    <p>
      <span
        >We have been delivering these services for a variety of companies
        including Bayer AG, Sandoz and Takeda.</span
      >
    </p>
    </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/training/training.jpg'),
    }
  },
}
</script>
